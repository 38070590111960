var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('validation-observer',{ref:"ShipmentCreateValidation"},[_c('b-row',[_c('b-col',{attrs:{"lg":"6"}},[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"shipment number*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"shipment number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter shipment number"},model:{value:(_vm.form.shipmentid),callback:function ($$v) {_vm.$set(_vm.form, "shipmentid", $$v)},expression:"form.shipmentid"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Consignee*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Consignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options},model:{value:(_vm.consignee),callback:function ($$v) {_vm.consignee=$$v},expression:"consignee"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Packing IDs*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Packing IDs","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"title","options":_vm.options1},model:{value:(_vm.packingids),callback:function ($$v) {_vm.packingids=$$v},expression:"packingids"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-1",attrs:{"lg":"10"}},[_c('b-form-group',{attrs:{"label":"Flight*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Flight"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter Total Weight"},model:{value:(_vm.form.totalweight),callback:function ($$v) {_vm.$set(_vm.form, "totalweight", $$v)},expression:"form.totalweight"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('h2',{staticClass:"form_label_class_heading"},[_vm._v("Additional cost")]),_c('br'),_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({ height: _vm.trHeight }),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain($event)}}},_vm._l((_vm.items1),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Description*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter description"},model:{value:(item.description),callback:function ($$v) {_vm.$set(item, "description", $$v)},expression:"item.description"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Amount*","label-class":"form_label_class"}},[_c('validation-Provider',{attrs:{"name":"Amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Enter amount"},model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('hr',{staticStyle:{"width":"100%","text-align":"left","margin-left":"0","background-color":"#000"}})],1)}),1)],1),_c('br'),_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"form_submit_button",attrs:{"variant":"none"},on:{"click":function($event){return _vm.repeateAgain1()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v("Add cost")])])],1)])])],1)],1),_c('br'),_c('br'),_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"form_submit_button",attrs:{"type":"submit","variant":"none"},on:{"click":function($event){return _vm.validationPackingCreateForm()}}},[_c('span',{staticClass:"button_text_styles"},[_vm._v(" Create")])])],1),_c('br'),_c('br')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }